.mdx {
  color: var(--mantine-color-gray-6);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  text-align: justify;

  p {
    color: var(--mantine-color-gray-6);
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  h1 {
    color: var(--mantine-color-gray-9);
    font-family: Inter, sans-serif;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 4.5rem;
    letter-spacing: -2%;
  }

  h2 {
    color: var(--mantine-color-gray-9);
    font-family: Inter, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;

    margin-bottom: var(--mantine-spacing-s1);
  }

  h3,
  h4,
  h5,
  h6 {
    color: var(--mantine-color-gray-9);
    font-family: Inter, sans-serif;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    margin-top: var(--mantine-spacing-s4);
  }

  img {
    border-radius: 8px;
    width: 100%;

    object-fit: cover;
    box-shadow: 0px 0px 18px -8px rgba(0, 0, 0, 0.5);
  }

  code {
    padding-inline: var(--mantine-spacing-s1);
    padding-block: var(--mantine-spacing-s1);
    border-radius: var(--mantine-radius-sm);

    background-color: color-mix(in srgb, black 10%, transparent);
  }

  a {
    color: var(--mantine-color-primary-7);
    text-decoration: underline;

    &:hover {
      color: var(--mantine-color-primary-9);
    }
  }
}
