.root {
  border-radius: 6px;
  padding-left: var(--mantine-spacing-s2);
  padding-right: var(--mantine-spacing-s2);
  padding-top: var(--mantine-spacing-s3);
  padding-bottom: var(--mantine-spacing-s3);

  &[data-variant='brandLightBordered'] {
    background-color: var(--brand-color-50);
    border: 1px solid var(--brand-color-600);
    color: var(--brand-color-600);

    text-transform: capitalize;

    &:hover {
      cursor: default;
    }
  }
}
